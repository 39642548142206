module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.vivintsource.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-5KHQ6K","brandTokens":["VIV"],"mapiBrandToken":"VIV","siteName":"vivintsource","alternateName":"vivintsource.com","siteURL":"https://www.vivintsource.com","defaultTitleTemplate":"","defaultPhone":"8556450403","phoneSymbol":"-","defaultPromoCode":"50256","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046067","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"vivintsource","short_name":"vivintsource","start_url":"/","background_color":"#E2B95D","theme_color":"#E2B95D","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6d988eccc7b3b48dd1d0352813be0ece"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
