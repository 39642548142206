import { withTheme } from '@emotion/react'
import React from 'react'
import GlobalCss from './GlobalCss.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
  </>
))

export default GlobalStyles
