import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      // For whatever reason, it looks like declaring these fonts directly in GlobalCss.styles
      // was the only way I could get them to render properly on the page. If there's a better
      // way to do this, I'm all ears.
      @font-face {
        font-family: 'Vivint Sans Medium';
        src: url('/fonts/VivintSans-Medium.otf') format('opentype');
      }

      @font-face {
        font-family: 'Vivint Circular Medium';
        src: url('/fonts/vivintCircular-Medium.otf') format('opentype');
      }

      @font-face {
        font-family: 'Vivint Circular Book';
        src: url('/fonts/vivintCircularWeb-Book.woff2') format('woff');
      }

      h1,
      h2,
      h3 {
        font-family: 'Vivint Sans Medium', sans-serif;
      }

      h4,
      h5,
      h6 {
        font-family: 'Vivint Circular Medium', helvetica;
      }

      p,
      a {
        font-family: 'Vivint Circular Book', sans-serif;
      }

      .leshen-hero {
        &.background-color-Dark {
          .leshen-price-change {
            span {
              color: white !important;
            }
          }
        }
      }
    `}
  />
))

export default GlobalCss
